import chunk from 'lodash.chunk';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { parseReportDateString } from 'business/report/services/timeOperations';
import { ReportChartMode } from 'business/report/types';
import {
  ActivityTreeFullFragment,
  ShiftFullFragment,
  TaskFullFragment,
} from 'generated/graphql';
import { useMediaType } from 'technical/media/hooks';

import './index.scss';
import { createShiftReportCharts, updateShiftReportCharts } from './services';
import { getUsedActivitiesList } from './services/activity';
import {
  SELECTOR,
  getPaddingLeft,
  margin,
  padding,
  setPaddingLeft,
} from './services/metadata/chart';
import { ExternalEventHandlers } from './services/metadata/types';

const TASK_HEIGHT = 45;

const taskInPages = {
  page1: 18,
  next: 32,
};

interface GetChartMetadataParams {
  activities: ActivityTreeFullFragment[];
  tasks: TaskFullFragment[];
  mode: ReportChartMode;
  node?: HTMLElement;
}
function getChartMetadata({
  activities,
  tasks,
  mode,
  node,
}: GetChartMetadataParams) {
  let baseElement = node;
  if (!baseElement) {
    baseElement = document.body;
  }

  const activitiesList = getUsedActivitiesList(activities, tasks, mode, false);

  setPaddingLeft(activitiesList);
  const paddingLeft = getPaddingLeft();

  const width = baseElement.clientWidth - padding.right - paddingLeft;

  if (mode === 'view' && activitiesList.length > taskInPages.page1) {
    const groups = [
      activitiesList.slice(0, taskInPages.page1),
      ...chunk(activitiesList.slice(taskInPages.page1), taskInPages.next),
    ];
    const metadata = groups.map((group) => ({
      width,
      height: group.length * TASK_HEIGHT + margin.top + margin.bottom,
      activities: group,
    }));
    return metadata;
  }

  const metadata = [
    {
      width,
      height: activitiesList.length * TASK_HEIGHT + margin.top + margin.bottom,
      activities: activitiesList,
    },
  ];
  return metadata;
}

interface Props {
  shift: ShiftFullFragment;
  date: string;
  activities: ActivityTreeFullFragment[];
  tasks: TaskFullFragment[];
  externalEventHandlers?: ExternalEventHandlers;
  criticalPathValid: boolean;
  mode?: ReportChartMode;
}

function ShiftReportChart({
  shift,
  date,
  activities,
  tasks,
  externalEventHandlers,
  criticalPathValid,
  mode = 'edit',
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [forceUpdate, setForceUpdate] = useState({});
  const { isMobile, isTablet } = useMediaType();

  // Create/Update chart based on tasks
  useLayoutEffect(() => {
    const metadata = getChartMetadata({
      activities,
      tasks,
      mode,
      node: ref.current?.parentElement ?? undefined,
    });
    if (!isMounted) {
      createShiftReportCharts({
        metadata,
        shift,
        tasks,
        externalEventHandlers,
        date: parseReportDateString(date, shift.startTime),
        isTablet: isTablet || isMobile,
        criticalPathValid,
        mode,
      });
      setIsMounted(true);
    } else {
      updateShiftReportCharts({
        metadata,
        shift,
        tasks,
        externalEventHandlers,
        date: parseReportDateString(date, shift.startTime),
        isTablet: isTablet || isMobile,
        criticalPathValid,
        mode,
      });
    }
  }, [
    forceUpdate,
    shift,
    tasks,
    activities,
    isMounted,
    externalEventHandlers,
    date,
    isMobile,
    isTablet,
    criticalPathValid,
    mode,
  ]);

  // Check window resize
  useLayoutEffect(() => {
    function handleResize() {
      setForceUpdate({});
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check orientation change
  useEffect(() => {
    function handleOrientationUpdate() {
      setForceUpdate({});
    }

    const screenOrientation = window.screen.orientation;
    if (!screenOrientation) {
      return () => {};
    }

    screenOrientation.addEventListener('change', handleOrientationUpdate);
    return () => {
      screenOrientation.removeEventListener('change', handleOrientationUpdate);
    };
  }, []);

  return <div ref={ref} id={`${SELECTOR}-${mode}`} />;
}

export default ShiftReportChart;
