import * as d3 from 'd3';
import { TimeInterval } from 'd3';
import dayjs, { Dayjs } from 'dayjs';

import { getShiftStartAndEndDate } from 'business/report/pages/ReportEdit/chartPageServices';
import { parseTaskDateString } from 'business/task/services/timeOperations';
import { ShiftFullFragment, TaskFullFragment } from 'generated/graphql';
import { TIME_HOUR_MINUTE_FORMAT } from 'technical/string/formatter';
import { ensureD3NumberValueIsDate } from 'technical/time-utils';

import { TOP_LEVEL_ACTIVITY_TEXT_CLASS, margin } from './metadata/chart';
import { Metadata } from './metadata/types';

export type ordinalScale = d3.ScaleBand<string>;
export type abscissaScale = d3.ScaleTime<number, number>;

export const initTimeDomain = (
  shift: ShiftFullFragment,
  tasks: TaskFullFragment[],
  date: Dayjs,
): [Date, Date] => {
  const firstTaskStartTime =
    tasks.length > 0 ? parseTaskDateString(tasks[0].startDate) : null;

  const { startDate: shiftStartTime, endDate: shiftEndTime } =
    getShiftStartAndEndDate(date, shift);

  const globalStartTime = firstTaskStartTime?.isBefore(shiftStartTime)
    ? firstTaskStartTime
    : shiftStartTime;
  const timeDomainStart = d3.timeHour.offset(globalStartTime.toDate(), 0);
  const timeDomainEnd = d3.timeHour.offset(shiftEndTime.toDate(), 0);
  if (tasks.length === 0) {
    return [timeDomainStart, timeDomainEnd];
  }
  return [timeDomainStart, timeDomainEnd];
};

export const initAxis = (
  [height, width]: [number, number],
  shift: ShiftFullFragment,
  tasks: TaskFullFragment[],
  activities: Metadata['activities'],
  date: Dayjs,
) => {
  const timeDomain = initTimeDomain(shift, tasks, date);

  const xScale = d3
    .scaleTime()
    .domain(timeDomain)
    .range([0, width])
    .clamp(true);

  // Change the type of interval for bigger scale to have more control because
  // passing the number of ticks directly may return more or less value which break
  // the display on daily timeline
  let xTimeInterval: number | TimeInterval = 8;
  if (dayjs(timeDomain[1]).tz().diff(dayjs(timeDomain[0]).tz(), 'hours') > 12) {
    xTimeInterval = d3.timeHour.every(2) ?? xTimeInterval;
  }

  // Remove start and end to concat them at the end of the array
  // Used to shift the position with CSS in front/src/business/report/components/ReportChart/index.scss
  const ticks = xScale
    // Missing type def for ticks
    // The 2 methods exists but they are typed separately
    // @ts-ignore
    .ticks(xTimeInterval)
    .filter(
      (tick) =>
        tick.valueOf() !== timeDomain[0].valueOf() &&
        tick.valueOf() !== timeDomain[1].valueOf(),
    );

  // If the last tick may overlap with the range end, remove it
  if (
    dayjs(timeDomain[1])
      .tz()
      .diff(dayjs(ticks[ticks.length - 1]).tz(), 'minutes') < 60
  ) {
    ticks.pop();
  }

  const xAxis = d3
    .axisBottom(xScale)
    // Limit the number of ticks to be displayed
    .tickValues(ticks.concat(timeDomain))
    .tickFormat((domainValue) => {
      // One overload is missing in type def
      return dayjs(ensureD3NumberValueIsDate(domainValue))
        .tz()
        .format(TIME_HOUR_MINUTE_FORMAT);
    })
    .tickSize(8)
    .tickPadding(2);

  // just use the activities in input
  const ordinalDomain = activities;
  const yScale = d3
    .scaleBand()
    .domain(ordinalDomain.map(({ names }) => names.join(' / ')))
    .rangeRound([0, height - margin.top - margin.bottom]);

  const yAxis = d3.axisLeft(yScale).tickSize(0);

  return {
    scales: { xScale, yScale },
    axis: { xAxis, yAxis },
    activitiesCount: ordinalDomain.length,
  };
};

export function splitActivityNamesOnTwoLines(
  ticks: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>,
) {
  ticks.each(function () {
    const self = d3.select(this);
    const splittedActivityNameAndParentName = self.text().split(' / ');
    const isMultiline = splittedActivityNameAndParentName.length === 2;

    // Absolute position inside the text element to create the gap between
    // the axis line and the tspan
    const tspanXAxisAbsolutePosition = -5;
    // Create the line break by changing the relative y coordinate of the tspan
    const tspanYAxisRelativePosition = '1.2em';
    // Move the text relative coordinate up to allign the 2 lines
    // with the task block
    const textMultilineRelativePosition = -2;

    // Reset the text value to add tspan
    self.text(null);

    // Add the first tspan
    // 1. is the activity itself if there is no parent
    // 2. is the activity parent so we manualy add the `/` separator
    self
      .append('tspan')
      .attr('x', tspanXAxisAbsolutePosition)
      .text(
        `${splittedActivityNameAndParentName[0]}${isMultiline ? ' /' : ''}`,
      );

    if (isMultiline) {
      // Move up the text to have more natural multiline
      self.attr('dy', textMultilineRelativePosition);

      // Add the second tspan used as a second line
      self
        .append('tspan')
        .attr('x', tspanXAxisAbsolutePosition)
        .attr('dy', tspanYAxisRelativePosition)
        .text(splittedActivityNameAndParentName[1]);
    } else {
      // If there is no multiline, it is a top level activity
      self.attr('class', TOP_LEVEL_ACTIVITY_TEXT_CLASS);
    }
  });
}
